import {RemixBrowser, useLocation} from '@remix-run/react';
import {BrowserTracing, init as initSentry, remixRouterInstrumentation} from '@sentry/remix';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import {StrictMode, startTransition, useEffect} from 'react';
import {hydrateRoot} from 'react-dom/client';

import {registerCustomTransformers, useMatches} from './features/serialization';

initSentry({
  dsn: window.ENV.SENTRY_DSN,
  environment: window.ENV.WEB_ENV ?? window.ENV.APP_ENV,
  integrations: [
    new BrowserTracing({
      routingInstrumentation: remixRouterInstrumentation(useEffect, useLocation, useMatches),
    }),
  ],
  normalizeDepth: 10,
  release: window.ENV.APP_VERSION,
  tracesSampler: context => (context.location?.pathname?.startsWith('/healthcheck') ? 0 : 0.01),
  tunnel: '/proxy/errors',
});

registerCustomTransformers();

dayjs.extend(duration);
dayjs.extend(localizedFormat);
dayjs.extend(relativeTime);

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>
  );
});
